<template>
<v-navigation-drawer v-model="isShow" app color="#39373F" dark>
    <!-- <v-list-item>
        <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
        <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
    </v-list-item> -->
    <v-btn 
        to="/"
        style=" background-color: transparent !important; box-shadow: none;"
    >
        <i class="fas fa-angle-left" style="padding: 0.2rem"></i> <v-text style="font-size: 16px;">ย้อนกลับ</v-text>
    </v-btn>
    <v-list-item style="border-bottom: 0px">
        <v-list-item-content>
            <v-list-item-title style="white-space: normal; font-size: 16px;" v-html="`วิชา ${courseDetail.title}`"></v-list-item-title>
            <v-list-item-subtitle class="py-2" style="white-space: normal; font-size: 14px;" v-html="`รหัสวิชา ${courseDetail.courseNumber}`"></v-list-item-subtitle>
            <hr>
            <v-list-item-subtitle class="py-2" style="white-space: normal; font-size: 14px;" v-html="`ปีการศึกษา ${courseDetail.semesterFull}`"></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>

    <v-divider style="border-width:1px; background-color: #fff!important;"></v-divider>
    <h5 class="white--text" style="margin-left:1rem; margin-top:1rem; font-size:20px; font-weight:500">จัดการวิชาเรียน</h5>
    <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link class="py-2" :to="item.path" :disabled="item.disable">
            <v-list-item-icon>
                <v-icon :class="item.disable?'icon-disable':''">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

    </v-list>

</v-navigation-drawer>
</template>

<script>
import apiGatewayCpmService from "@/services/apiGatewayService"

export default {
    name: 'TheSidebar',
    components: {},
    created() {
        this.getCourseDetail(this.$route.params.courseId)
    },
    mounted() {
        this.setPathEventId()
    },
    computed: {
        isShow: {
            get() {
                return this.$store.state.sideBarIsShow
            },
            set(val) {
                console.log("sidebar:", val)
                // this.$store.commit('increment')
                val ? this.$store.commit('setSideBarShow') : this.$store.commit('setSideBarHide')
            }
        },
        courseData: {
            get() {
                return this.$store.state.sideBarData
            },
            set() {
                this.$store.commit('setSideBarData')
            }
        }
    },
    watch: {
        items: function (newVal) {
            console.log("items:", newVal)
        }
    },
    data: () => ({
        courseDetail: {},
        drawer: true,
        items: [{
                title: 'Instructor Management',
                icon: 'mdi-account-details',
                path: `/`,
                disable: false
            },
            {
                title: 'LMS',
                icon: 'mdi-alpha-m-box',
                path: `/`,
                disable: false
            },
            {
                title: 'MS Teams',
                icon: 'mdi-account-group',
                path: `/`,
                disable: false
            },
            {
                title: 'Grade Book',
                icon: 'mdi-book',
                path: `/`,
                disable: true
            },
            {
                title: 'Exam Manager',
                icon: 'mdi-bookshelf',
                path: `/`,
                disable: false
            },
            {
                title: 'CMU Exam',
                icon: 'mdi-script-text-outline',
                path: `/`,
                disable: false
            }
            

        ],
    }),
    methods: {
        setPathEventId() {
            // console.log("this.$route.params.eventId:",this.$route.params)
            this.items[0].path = `/courses/${this.$route.params.courseId}/course-management`
            this.items[1].path = `/courses/${this.$route.params.courseId}/lms`
            this.items[2].path = `/courses/${this.$route.params.courseId}/teams`
            this.items[3].path = `/courses/${this.$route.params.courseId}/gradebook`
            this.items[4].path = `/courses/${this.$route.params.courseId}/oem`
            this.items[5].path = `/courses/${this.$route.params.courseId}/exam`
        },
        async getCourseDetail(courseId) {
            console.log("getCourseDetail")
            let courseDetail = await apiGatewayCpmService.getCourseDetail(courseId, localStorage.getItem('accessToken'))
            if (courseDetail) {
                courseDetail.semesterFull = this.getCouseFullSemester(courseDetail.semester)
                this.courseDetail = courseDetail
                this.$store.commit('setSideBarData', courseDetail)
                console.log("setSideBarData", courseDetail)
            }
        },
        getCouseFullSemester(shortSemester) {
            let semesterArr = shortSemester.split("")
            // console.log("semesterArr:",semesterArr)
            if (`${semesterArr[0]}` !== "4") {
                return `${semesterArr[0]}/25${semesterArr[1]}${semesterArr[2]}`
            } else {
                return `25${semesterArr[1]}${semesterArr[2]}`
            }

        }
    }
}
</script>

<style>
.v-list .v-list-item--active {
    color: #FAAC1E !important;
    background-color: #6868AC;

}


.icon-disable{
    color : gray !important;
}
.v-list-item--link:before {
    background-color: #6868AC;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item {
    border-bottom: 1px solid white;
}
</style>
